import React, { useState } from 'react';
import PropTypes from 'prop-types';

import request from 'superagent';

import RESUME_LS_RECORD from 'shared-constants/src/resumeLSRecord';
import PAGES_TYPE from 'shared-constants/src/pagesType';

import SearchBuilder from 'shared-components/components/SearchBuilder';

import { computeActiveFilters } from 'shared-utils/src/helpersMyCasa';
import integerWithEuropeThousandSeparator from 'shared-utils/src/integerWithThousandSeparator';

import { setItem } from '@helpers/Storage';
import { scrollToTop } from '@helpers/DOMHelpers';
import { createDefaultAgencyName } from '@helpers/searchAgency/SearchAgency';
import {
  normalizeSRPUrl,
  computeHowToGoBack,
  saveRecentSearchesEntryHP,
} from '@helpers/search/Search';

import UiHandlerHOC from '../app/UiHandler';

import { fetchSearch } from '../../common/redux/search/actions';

import { fetchLocationById } from '../components/common/search/helpers/search';

import Search from './Search';

const createInitialFilters = (serverFilters, agencyFilters) => {
  const agencyFreetext = createDefaultAgencyName(agencyFilters);
  return { ...serverFilters, agencyData: Object.keys(agencyFreetext).length > 0 ? { ...agencyFreetext } : null };
};

const SearchPageComponent = (props) => {
  const {
    device,
    orientation,
    config: {
      esapi,
      siteName,
      siteUrl,
      layout: {
        recentSearchesLocalStorageKey,
      },
    },
    intl: {
      currentLocale,
    },
    search: {
      locale,
      domain,
      list,
      fewResults,
      clientFilters: {
        filters: serverFilters,
      },
      agencyFilters,
      locations: serverLocations,
      parentLocation: serverParentLocations,
      hasPublisherHeading,
      isPublisherHP,
      isAgencySrp,
      paginator,
      availableResults,
      uri: serverUri,
      apireq: serverApireq,
    },
    actions: {
      fetchListingOnFiltersChange,
      fetchSRPAvailableResults,
      fetchSRPAgencyAvailableResults,
      fetchAgencyListingOnFiltersChange,
    },
    location: {
      pathname,
    },
    history: {
      goBack,
    },
  } = props;

  const [initialFilters] = useState(createInitialFilters(serverFilters, agencyFilters));
  const [initialLocations] = useState({
    locations: serverLocations,
    parent: serverParentLocations && serverParentLocations.length ? serverParentLocations[0] : {},
  });

  const [forcedAuction, setForcedAuctionState] = useState(false);
  const [collapsePublisherInfos, setCollapsePublisherInfos] = useState(false);
  const [fillInExpanded, setFillInExpanded] = useState(fewResults ? fewResults.startsExpanded : false);
  const [userSortedNewAds, setUserSortedNewAds] = useState(false);

  const [backUriCollection, setBackUriCollection] = useState([{
    uri: serverUri,
    filters: initialFilters,
    locations: initialLocations.locations,
    parentLocation: initialLocations.parent,
    isPublisherHP,
    isAgencySrp,
    agencyFreetext: initialFilters.agencyData,
  }]);
  // It needs to be a state because it's used in the back button
  // and it has to know if the floorplan is opened or not
  const [floorplanOpened, setFloorplanOpened] = useState(false);

  const saveRLSRecord = (dataObj) => {
    const rlsData = { ...dataObj };
    if (rlsData?.filters?.fillin) {
      delete rlsData.filters.fillin;
    }
    window.localStorage && setItem(RESUME_LS_RECORD, JSON.stringify(rlsData));
  };

  const computeAPIReq = async ({
    locations,
    filters,
    forceMap = false,
  }) => {
    const proxy = await request.post('/portal-srp/api/v1/apireq')
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .withCredentials()
      .send({
        locations,
        filters,
        apireqParams: {
          pathname,
          domain: siteName,
          locale: currentLocale,
          esapi,
          publisherPage: false,
        },
        pageType: forceMap ? PAGES_TYPE.SRP_MAP : PAGES_TYPE.SRP,
      });
    const { body: { apireq } } = proxy;
    return apireq;
  };

  const computeAgencyAPIReq = async ({
    locations,
    activeFilters,
    agencyItem,
  }) => {
    const proxy = await request.post('/portal-srp/api/v1/apireq/agency')
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .withCredentials()
      .send({
        config: {
          domain,
          locale,
          esapiConfig: esapi,
        },
        params: {
          locations,
          activeFilters,
          agencyItem,
          type: 'agencySrp',
        },
      });
    const { body: { apireq } } = proxy;
    return apireq;
  };

  const fetchSBAvailableResults = async ({
    locations,
    filters,
  }) => {
    let apireq;
    let proxyUrl;
    if (isAgencySrp) {
      const agencyItem = filters.agencyData || {};
      const activeFilters = { channel: filters.channel === 'affitti' ? 'affitto' : filters.channel };
      delete activeFilters.agencyData;
      apireq = await computeAgencyAPIReq({
        locations,
        activeFilters,
        agencyItem,
      });
      proxyUrl = '/portal-srp/api/v1/agencySearch/availableresults';
    } else {
      apireq = await computeAPIReq({
        locations,
        filters,
      });
      proxyUrl = '/portal-srp/api/v1/search/availableresults';
    }

    const proxy = await request.post(proxyUrl)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .withCredentials()
      .send({ apireq: apireq.apireq });

    return {
      total: proxy?.body?.total || 0,
      ptgrpsAggregates: proxy?.body?.ptgrpsAggregates || null,
    };
  };

  const updateBackUriCollection = ({
    newuri,
    locations,
    parentLocation,
    filters,
  }) => {
    if (newuri !== backUriCollection[backUriCollection.length - 1].uri) {
      const newCollection = [...backUriCollection, {
        uri: newuri,
        filters,
        locations,
        parentLocation,
        isPublisherHP,
        isAgencySrp,
        agencyFreetext: filters.agencyData,
      }];
      setBackUriCollection(newCollection);
    }
  };

  const fetchResults = async ({
    locations,
    parentLocation,
    filters,
    keepPage,
    mobileUpdate,
    fromForcedAuction,
  }) => {
    if (isAgencySrp) {
      const agencyItem = filters.agencyData || {};
      const sortType = (filters.sortType && filters.sortType.indexOf('relevance', 'default') > -1) ? null : filters.sortType;
      const activeFilters = { channel: filters.channel === 'affitti' ? 'affitto' : filters.channel, ...sortType && { sortType } };
      delete activeFilters.agencyData;
      const apireq = await computeAgencyAPIReq({
        locations,
        activeFilters,
        agencyItem,
      });
      // if (device === 'smartphone' && mobileUpdate) {
      //   console.log('entro qua aa >>:',);
      //   apireq.apireq.size = 0;
      //   fetchSRPAgencyAvailableResults(apireq);
      // } else {

      updateBackUriCollection({
        newuri: apireq.uri,
        locations,
        parentLocation,
        filters,
      });
      fetchAgencyListingOnFiltersChange(apireq);

    } else {
      const apireq = await computeAPIReq({
        locations,
        filters,
      });

      if (isPublisherHP || hasPublisherHeading) {
        setCollapsePublisherInfos(true);
        setTimeout(
          () => {
            updateBackUriCollection({
              newuri: apireq.uri,
              locations,
              parentLocation,
              filters,
            });
            fetchListingOnFiltersChange(apireq);
            scrollToTop();
          }, 651,
        );
      } else if (
        device === 'desktop'
        || (
          device === 'tablet'
          && orientation === 'landscape'
        )
        || mobileUpdate
      ) {
        if (!filters.pId) {
          saveRLSRecord({
            locations,
            parentLocation,
            filters,
          });
          saveRecentSearchesEntryHP({
            localities: locations,
            parentLocation,
            filters,
            uri: apireq.uri,
            localStorageKey: recentSearchesLocalStorageKey,
            deduplicate: true,
          });
        }

        if (keepPage) {
          const page = paginator.currentPage || 1;
          if (page !== 1) {
            const uri = apireq.uri.indexOf('?') > -1
              ? `${apireq.uri}&page=${page}`
              : `${apireq.uri.slice(0, -1)}?page=${page}`;
            apireq.uri = uri;
            apireq.queryFilters.page = page;
            apireq.apireq.page = page;
          }
        }

        if (fromForcedAuction || forcedAuction) {
          const newForcedAuction = fromForcedAuction || false;
          setForcedAuctionState(newForcedAuction);
        }

        updateBackUriCollection({
          newuri: apireq.uri,
          locations,
          parentLocation,
          filters,
        });


        if (fillInExpanded) {
          apireq.fillInExpanded = true;
        }

        fetchListingOnFiltersChange(apireq);
      } else {
        fetchSRPAvailableResults(apireq);
      }
    }
  };

  const startSearch = async ({
    locations,
    parentLocation,
    filters,
    forceMap,
  }) => {
    let apireq;
    if (isAgencySrp) {
      const agencyItem = filters.agencyData || {};
      const activeFilters = { ...filters };
      delete activeFilters.agencyData;
      apireq = await computeAgencyAPIReq({
        locations,
        activeFilters,
        agencyItem,
      });
    } else {
      apireq = await computeAPIReq({
        locations,
        filters,
        forceMap,
      });
      saveRLSRecord({
        locations,
        parentLocation,
        filters,
      });
      saveRecentSearchesEntryHP({
        localities: locations,
        parentLocation,
        filters,
        uri: apireq.uri,
        localStorageKey: recentSearchesLocalStorageKey,
      });
    }

    const newUrl = normalizeSRPUrl(apireq.uri);
    document.location = newUrl;
  };

  const computeLastListingData = (isFillin = false) => {
    const tot = parseInt(availableResults.replace('.', ''), 10);
    if (fillInExpanded || isFillin) {
      return {
        llItems: [...list, ...fewResults.listings],
        llTotal: tot + parseInt(fewResults.total, 10),
        llfillIn: 1,
      };
    }
    if (fewResults) {
      if (fewResults.page === 1) {
        if (fewResults.isNoResults && fewResults.listings.length > 0) {
          const [first, second, third] = fewResults.listings;
          const available = [first, second, third].filter(Boolean);
          return {
            llItems: available,
            llTotal: available.length,
            llfillIn: 0,
          };
        }
      } else {
        return {
          llItems: [...list],
          llTotal: tot + parseInt(fewResults.total, 10),
          llfillIn: 1,
        };
      }
    }
    return {
      llItems: [...list],
      llTotal: tot,
      llfillIn: 0,
    };
  };

  const setLastListingRecord = async ({
    href,
    classList,
    locations,
    filters,
    isFillin,
    activeNearby,
  }) => {
    const loc = document.location;
    const page = paginator.currentPage || 1;

    const { llItems, llTotal, llfillIn } = computeLastListingData(isFillin);

    const lastListingParams = loc.search.substring(1).split('&');
    if (llfillIn) {
      lastListingParams.push('fillin=open');
    }

    const params = lastListingParams.reduce((acc, item) => {
      const [key, ...values] = item.split('=');
      key && (acc[key] = values.join('='));
      return acc;
    }, {});

    const apireq = await computeAPIReq({
      locations,
      filters,
    });

    const artId = href.replace('immobili', '').replace(/\//g, '');
    const openedFrom = classList.contains('csaSrpcard__units__grid')
      ? artId.split('#')[0]
      : artId;

    let pages = paginator.totalPages;
    if (!pages && llItems.length) {
      pages = 1;
    }

    setItem('backToListing', 1); // Able to use history back from PDP
    setItem('__lastListing', JSON.stringify({
      isFromMap: false,
      page,
      pages,
      perPage: paginator.articlesForPage,
      items: { [page]: llItems.map(el => el.id) },
      total: integerWithEuropeThousandSeparator(llTotal),
      path: loc.pathname,
      params,
      apireq,
      openedFrom,
      isPublisherHP,
      isFillin,
      locations,
      activeNearby,
      timestamp: new Date().getTime(),
    }));
  };

  const doLoadDetail = async (evt, href, isFillin, rightCLick, locations, filters, activeNearby) => {
    if (!rightCLick && !evt.metaKey) {
      evt.preventDefault();
    }
    const tgt = evt.target;
    const { classList } = tgt;

    // const skipClasses = [
    //   'modal_handle',
    //   'sp',
    //   'publisherDetail',
    //   'expand',
    // ];

    // const skipTxts = [
    //   'Agenzia',
    //   'Costruttore',
    // ];

    // if (
    //   skipTxts.indexOf(textContent) >= 0
    //   || (classList && skipClasses.some(n => classList.contains(n)))
    // ) {
    //   return;
    // }

    await setLastListingRecord({
      href,
      classList,
      locations,
      filters,
      isFillin,
      activeNearby,
    });

    if (!rightCLick && !evt.metaKey) {
      const destinationUrl = document.location.host.includes('local')
        ? `http://local.casa.it:3000${href}`
        : href;

      document.location = destinationUrl;
    }
  };

  return (
    <>
      <SearchBuilder
        initialFilters={initialFilters}
        initialLocations={initialLocations}
        fetchLocationById={fetchLocationById}
      >
        {
          (sbProps) => {
            const {
              setCurrentNearby,
              setCurrentFilters,
              setCurrentLocation,
              computeSearchFilters,
            } = sbProps;

            const sbfilters = {
              ...sbProps.filters,
              ...{
                category: sbProps.filters.propertyTypeGroup || sbProps.filters.category,
              },
            };

            const setFilters = (newfilter, forceMobileUpdate = false) => setCurrentFilters({ ...sbfilters, ...newfilter }, (updatedFilters) => {
              const updateSortType = Object.keys(newfilter)[0] === 'sortType';
              const mobileUpdate = forceMobileUpdate || updateSortType || Object.keys(newfilter)[0] === 'agencyData';
              const definitiveFilters = { ...updatedFilters };
              /**
               * Every time we change the sort type we want to show 
               * the banner for save search
               */
              setUserSortedNewAds(true);

              if (updateSortType && fewResults) {
                setFillInExpanded(true);
                if (fewResults.page > 1) {
                  definitiveFilters.fillin = 'open';
                }
              } else {
                setFillInExpanded(false);
              }

              const fetchParams = {
                locations: sbProps.locations.search,
                parentLocation: sbProps.locations.searchParent,
                filters: definitiveFilters,
                mobileUpdate,
              };

              fetchResults(fetchParams);
            });

            const setNewSearchFromSaveSearchModal = (newLocations, newParentLocation, newfilters) => {
              setCurrentLocation(newLocations, newParentLocation, {}, (updatedLocations, updatedParentLocation, tempFilters) => {
                setCurrentFilters({ ...tempFilters, ...newfilters }, (updatedFilters) => {
                  const fetchParams = {
                    locations: updatedLocations,
                    parentLocation: updatedParentLocation,
                    filters: updatedFilters,
                    mobileUpdate: true,
                  };
                  fetchResults(fetchParams);
                });
              });
            };

            const getAvailableResults = async ({
              newLocations = null,
              newFilters = {},
              deleteGeoFilters = false,
            }) => {
              const fetchParams = {
                locations: newLocations || sbProps.locations.search,
                filters: computeSearchFilters({ ...sbfilters, ...newFilters }),
              };

              // quando chiediamo gli available results con un cambio di località
              // ci viene chiesto di resettare eventuali filtri geo persistenti
              if (deleteGeoFilters) {
                delete fetchParams.filters.geobounds;
                delete fetchParams.filters.geocircle;
                delete fetchParams.filters.geopolygon;
              }
              const av = await fetchSBAvailableResults(fetchParams);
              return av;
            };

            const setForcedAuction = () => setCurrentFilters({ ...sbfilters, is_auction: false }, (updatedFilters) => {
              const fetchParams = {
                locations: sbProps.locations.search,
                parentLocation: sbProps.locations.searchParent,
                filters: updatedFilters,
                mobileUpdate: true,
                fromForcedAuction: true,
              };
              fetchResults(fetchParams);
            });

            const setLocations = (choice, parent) => {
              setCurrentLocation(choice, parent, {}, (updatedLocations, updatedParentLocation, updatedFilters) => {
                const fetchParams = {
                  locations: updatedLocations,
                  parentLocation: updatedParentLocation,
                  filters: updatedFilters || sbProps.filters,
                };
                fetchResults(fetchParams);
              });
            };

            const setNearby = userNearby => setCurrentNearby(userNearby, (updatedLocations, updatedParentLocation, updatedFilters) => {
              const fetchParams = {
                locations: updatedLocations,
                parentLocation: updatedParentLocation,
                filters: updatedFilters,
              };
              fetchResults(fetchParams);
            });

            const submitSearch = ({
              locations,
              parentLocation,
              filters,
              forceMap,
            }) => startSearch({
              locations: locations || sbProps.locations.search,
              parentLocation: parentLocation || sbProps.locations.searchParent,
              filters: filters || sbfilters,
              forceMap,
            });

            const loadDetail = (ev, href, isFillin = false, rightCLick = false, activeNearby = null) => doLoadDetail(ev, href, isFillin, rightCLick, sbProps.locations.search, sbProps.filters, activeNearby);

            const browserBackButton = async () => {
              if (backUriCollection.length > 1) {
                const params = {
                  domain,
                  currentLocale,
                  esapi,
                  fetchAgencyListingOnFiltersChange,
                  fetchListingOnFiltersChange,
                  goBack,
                  pageType: PAGES_TYPE.SRP,
                  backUriCollection,
                };
                const data = await computeHowToGoBack(params);

                setBackUriCollection(data.backUriCollection);
                setCurrentLocation(data.newState.locations, data.newState.parentLocation, {}, () => setCurrentFilters(data.newState.filters));
                setCollapsePublisherInfos(data.newState.collapsePublisherInfos);
              } else if (floorplanOpened) {
                setFloorplanOpened(false);
              } else {
                goBack();
              }
            };

            const startWithSelectedPtypes = initialFilters.propertyTypes && Array.isArray(initialFilters.propertyTypes)
              ? initialFilters.propertyTypes.length > 0
              : false;

            return (
              <Search
                filters={sbfilters}
                activeFilters={computeActiveFilters(sbProps.filters)}
                locations={sbProps.locations.search}
                parentLocation={sbProps.locations.searchParent}
                mainLocation={sbProps.locations.ui.main}
                additionalLocations={sbProps.locations.ui.additional}
                collapsePublisherInfos={collapsePublisherInfos}
                forcedAuction={forcedAuction}
                setFilters={setFilters}
                getAvailableResults={getAvailableResults}
                setForcedAuction={setForcedAuction}
                setLocations={setLocations}
                setNearby={setNearby}
                setNewSearchFromSaveSearchModal={setNewSearchFromSaveSearchModal}
                fillInExpanded={fillInExpanded}
                setFillInExpanded={setFillInExpanded}
                submitSearch={submitSearch}
                loadDetail={loadDetail}
                browserBackButton={browserBackButton}
                startWithSelectedPtypes={startWithSelectedPtypes}
                userSortedNewAds={userSortedNewAds}
                setFloorplanOpened={setFloorplanOpened}
                {...props}
              />
            );
          }
        }
      </SearchBuilder>
    </>
  );
};

SearchPageComponent.propTypes = {
  device: PropTypes.string,
  orientation: PropTypes.string,
  actions: PropTypes.instanceOf(Object),
  config: PropTypes.instanceOf(Object),
  search: PropTypes.instanceOf(Object),
  intl: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  isLocalStorageAvailable: PropTypes.bool,
};

SearchPageComponent.defaultProps = {
  device: '',
  orientation: '',
  actions: {},
  config: {},
  search: {},
  intl: {},
  location: {},
  history: {},
  user: {},
  isLocalStorageAvailable: false,
};

const SearchPage = UiHandlerHOC(SearchPageComponent, fetchSearch);
export default SearchPage;
