import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from 'shared-components/components/Grid';

import WatchScroll from 'shared-components/components/WatchScroll';
import FewResultsNextPagesTitle from '@async-components/FewResultsNextPagesTitle';

import { useCtxGlobal } from '@client/app/Container';

import './style.scss';

const ListContainer = ({ size, total, expand, sortType, children }) => {
  const {
    isMobile,
    model: { uri },
  } = useCtxGlobal();
  const fewbanner = useRef(null);

  const [contMinHeight, setContMinHeight] = useState(null);

  useEffect(() => {
    if (typeof isMobile !== 'string') {
      setTimeout(() => {
        setContMinHeight(`${fewbanner.current.scrollHeight + 16}px`);
      }, 100);
    }
  }, [isMobile, uri]);


  return (
    <div className="is-rel mt--m" style={{ minHeight: contMinHeight }}>
      <div
        ref={fewbanner}
        className={`fill_few__banner c-bg--w${size ? ' out' : ''}`}
      >
        <p className="mb--xs tp-s--x2l tp-w--l c-txt--f0">
          Al momento non ci sono altri annunci con le caratteristiche che
          cerchi.
        </p>
        <p className="mb--s tp-s--m tp-w--m c-txt--f5">
          Allargando la ricerca abbiamo trovato{' '}
          <span className="tp-w--l">
            {total} immobil{total === 1 ? 'e' : 'i'} con le caratteristiche che
            stavi cercando
          </span>
        </p>
        <button
          type="button"
          className="fill_few__banner__trig pr--m pl--m tp-s--l tp-w--m c-bg--w c-br--f3 b--s c-txt--secondary b-r--m is-clickable"
          onClick={expand}
        >
          Vedi {total} annunc{total === 1 ? 'io' : 'i'} circostant
          {total === 1 ? 'e' : 'i'}
        </button>
      </div>

      <div className={`fill_few__title pt--l ml--m ${size ? ' in' : ''}`}>
        <p className="mb--xs tp-s--x2l tp-w--l c-txt--f0">
          Stai vedendo gli annunci vicini alla tua area di ricerca
        </p>
        <p className="mb--s tp-s--m tp-w--m c-txt--f5">
          Al momento non ci sono altri annunci con le caratteristiche che cerchi
          nella zona che desideri, ma ne abbiamo trovati{' '}
          <span className="tp-w--l">{total} nelle aree circostanti</span>.
        </p>
      </div>
      {/* {size && (
        <WatchScroll>
          {scrollInfos => (
            <FewResultsNextPagesTitle scrollInfos={scrollInfos} />
          )}
        </WatchScroll>
      )} */}
      {children}
    </div>
  );
};

export default ListContainer;

ListContainer.propTypes = {
  total: PropTypes.number,
  expand: PropTypes.func,
  size: PropTypes.string,
  sortType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

ListContainer.defaultProps = {
  total: 0,
  expand: () => {},
  size: null,
  sortType: 'relevance',
};
