import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import makeClassName from 'shared-utils/src/makeClassName';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import sleep from 'shared-utils/src/sleep';

import { useCtxGlobal } from '@client/app/Container';

import Card from '@components/searchPage/results/Card';
import SortTypeLink from '@components/searchPage/SortTypeLink';

import NoResultsTop from './components/NoResultsTop';
import ListContainer from './components/ListContainer';
import Publishers from './components/Publishers';

import {
  getIds,
  createList,
  compareIdsList,
  computePEvents,
} from './helpers';

import './style.scss';

const FewResultsFillIn = ({
  loadDetail,
  updateSearchPaginator,
  collapseMandateBanner,
  fillInExpanded,
  setFillInExpanded,
  userLogIn,
  viewport,
  sortType,
  setCurrentFloorplan,
  searchIsSaved,
  setShowTitleFewerResults,
  setHeigthStartFewResults,
}) => {
  const {
    isMobile,
    isSmallMobile,
    iosLth12,
    globalSelector,
    globalReducer,
    globalState: {
      favorites,
      config: {
        env,
        cookiesConfig = {},
        api: {
          account,
          authBasePrefix,
        },
      } = {},

    } = {},
    model: {
      isPublisherHp,
      isPublisherPage,
      fewResults,
      isFetching,
      queryFilters,
      domain,
      total: modelTotal,
      resolvedLocations,
    },
    logIn,
    showSynchingDialog,
    openModal,
    closeModal,
    siteUrl,
    filtersProps,
    filtersProps: {
      device,
      filters,
      locations,
    },
    userConf: {
      userUrls: {
        userhost,
      },
    },
    pageType,
    setNewSearchFromSaveSearchModal,
    getUser,
    getUserLoginPayload,
    updateUserFormData,
    saveBookmarkSuccess,
    user,
  } = useCtxGlobal();

  const {
    page,
    listings,
    total,
    isNoResults,
    nextLink,
  } = fewResults;

  const cardProps = {
    isMobile,
    isSmallMobile,
    iosLth12,
    globalSelector,
    globalReducer,
    cookiesConfig,
    account,
    authBasePrefix,
    total: modelTotal,
    isPublisherHp,
    isPublisherPage,
    logIn,
    showSynchingDialog,
    openModal,
    closeModal,
    siteUrl,
    filtersProps,
    device,
    filters,
    locations,
    userhost,
    pageType,
    setNewSearchFromSaveSearchModal,
    getUser,
    getUserLoginPayload,
    updateUserFormData,
    saveBookmarkSuccess,
    favorites,
    user,
    searchIsSaved,
  };

  const accContainer = useRef(null);

  const [list, setList] = useState(createList(listings, isNoResults));
  const [size, setSize] = useState(fillInExpanded ? 'none' : null);
  const [pEvents, setPEvents] = useState(computePEvents({ fillInExpanded, isNoResults }));
  const [nopub, setNopub] = useState(fillInExpanded);
  const [revealAll, setRevealAll] = useState(fillInExpanded);

  useEffect(() => setHeigthStartFewResults(accContainer.current && accContainer.current.getBoundingClientRect().top + window.scrollY), []);

  useEffect(() => {
    if (listings.length !== list.length || getIds(listings).join('_') !== list.ids.join('_')) {
      setList(createList(listings, isNoResults));
      setPEvents(computePEvents({ fillInExpanded, isNoResults }));
      if (compareIdsList(getIds(listings), list.ids).length !== list.length && !fillInExpanded) {
        setSize(null);
        setShowTitleFewerResults(false);
        setNopub(false);
        setRevealAll(false);        
      } else {
        setSize(fillInExpanded ? 'none' : null);
        setShowTitleFewerResults(!!fillInExpanded);
        setNopub(fillInExpanded);
        setRevealAll(fillInExpanded);
      }
    }
  }, [listings, list, fillInExpanded]);

  const expand = async () => {
    sleep(300).then(() => trackGTMEvents({
      category: 'NoFewResults',
      action: 'SeePropertiesAround',
      label: total.toString(),
    }, {}));
    if (page === 0) {
      document.location = nextLink;
    } else {
      const time = isMobile
        ? 300
        : 150;

      const apiQf = JSON.parse(JSON.stringify(queryFilters));
      if (apiQf.propertyTypeGroup === 'nuove-costruzioni') {
        apiQf.propertyTypeGroup = 'case';
        apiQf.buildingCondition = 'nuovo';
      }

      updateSearchPaginator({
        queryFilters: apiQf,
        domain,
        totalResults: modelTotal + total,
        resolvedLocations,
      });

      setSize('none');    
      setShowTitleFewerResults(true);
      setPEvents('all');

      setTimeout(() => {
        list.items.length && [...accContainer.current.children].forEach((child, index) => setTimeout(() => child.classList.add('show'), index * time));
        
        setTimeout(() => {
          setNopub(true);
        }, list.items.length * time);

        setFillInExpanded(true);
      });
    }
  };

  
  const expandingList = list.items.length > 0 ? (
    <div
      ref={accContainer}
      className={`fill__acc${!isNoResults ? ' forfew' : ''}`}
      style={{ maxHeight: size, pointerEvents: pEvents }}
    >
      {
        list.items.map((article, x) => {
          const isLast = x === list.items.length - 1;
          const cls = makeClassName([
            'fill__acc__item',
            revealAll && 'show',
          ]);
          return (
            <div
              key={`article_${article.id}`}
              className={cls}
            >
              <Card
                article={article}
                isSixth={false}
                isFetching={isFetching}
                loadDetail={loadDetail}
                collapseMandateBanner={collapseMandateBanner}
                viewport={viewport}
                isFillin
                setCurrentFloorplan={setCurrentFloorplan}
                userLogIn={userLogIn}
                {...cardProps}
              />
              {isLast && sortType === 'relevance' && fillInExpanded
                ? <SortTypeLink listingEnd env={env} />
                : null
              }
            </div>
          );
        })
      }
    </div>
  ) : null;

  return (
    <div>
      {isNoResults && total > 0 && page === 1 && (
        <NoResultsTop
          total={total}
          top={list.top}
          trigger={list.trigger}
          expand={expand}
          size={size}
          loadDetail={loadDetail}
          collapseMandateBanner={collapseMandateBanner}
          viewport={viewport}
          userLogIn={userLogIn}
          cardProps={cardProps}
          setCurrentFloorplan={setCurrentFloorplan}
          
        />
      )}
      {
        !isNoResults && (expandingList || (total > 0 && page === 0)) ? (
          <ListContainer
            isNoResults={isNoResults}
            total={total}
            page={page}
            nextLink={nextLink}
            size={size}
            expand={expand}
            sortType={sortType}
          >
            {expandingList}
          </ListContainer>
        ) : expandingList
      }
      {!nopub && (
        <Publishers
          size={size}
          isNoResults={isNoResults}
        />
      )}      
    </div>
  );
};

export default FewResultsFillIn;

FewResultsFillIn.propTypes = {
  loadDetail: PropTypes.func,
  updateSearchPaginator: PropTypes.func,
  collapseMandateBanner: PropTypes.func,
  fillInExpanded: PropTypes.bool,
  setFillInExpanded: PropTypes.func,
  userLogIn: PropTypes.func,
  viewport: PropTypes.instanceOf(Object),
  sortType: PropTypes.string,
  setCurrentFloorplan: PropTypes.func,
  setHeigthStartFewResults: PropTypes.func,
  searchIsSaved: PropTypes.bool,
  setShowTitleFewerResults: PropTypes.func,
};

FewResultsFillIn.defaultProps = {
  loadDetail: () => { },
  updateSearchPaginator: () => { },
  collapseMandateBanner: () => { },
  fillInExpanded: false,
  setFillInExpanded: () => { },
  userLogIn: () => { },
  viewport: {},
  sortType: 'relevance',
  setCurrentFloorplan: () => { },
  setHeigthStartFewResults: () => { },
  searchIsSaved: false,
  setShowTitleFewerResults: () => { },
};
