import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import trackTLEvents from 'shared-utils/src/trackTLEvents';
import sleep from 'shared-utils/src/sleep';
import openSpecificModal from 'shared-utils/src/leads/openSpecificModal';
import openProposeSaveSearchModal from 'shared-utils/src/saveSearch/openProposeSaveSearchModal';

import LazyLoad from 'shared-components/components/LazyLoad';
import SrpCard from 'shared-components/components/SrpCard';

import {
  createLoginModal,
  createPhonecallModal,
  createSpecificModal,
  createGenericAfterSpecificModal,
  createFeedbackModal,
  createProposeSaveSearchModal,
  saveSearchQb,
  createNewSaveSearchFeedbackModal,
  createFloorplanModal,
} from '../../../modals/factories';

function Card({
  article,
  isSixth,
  isFetching,
  loadDetail,
  isFillin,
  collapseMandateBanner,
  viewport,
  userLogIn,
  setCurrentFloorplan,
  isMobile,
  isSmallMobile,
  iosLth12,
  globalSelector,
  globalReducer,
  cookiesConfig,
  account,
  authBasePrefix,
  total,
  isPublisherHp,
  isPublisherPage,
  logIn,
  showSynchingDialog,
  openModal,
  closeModal,
  siteUrl,
  filtersProps,
  device,
  filters,
  locations,
  userhost,
  pageType,
  setNewSearchFromSaveSearchModal,
  getUser,
  getUserLoginPayload,
  updateUserFormData,
  saveBookmarkSuccess,
  favorites,
  user,
  searchIsSaved,
}) {
  const {
    id,
    uri,
    leadsData,
    refPublisher,
    proposeGeneric,
    publisher: {
      publisherId,
      publisherSlug,
      publisherPhone,
      hasCrossSubscription,
    },
    isProjectProfile,
  } = article;

  const [saved, setSaved] = useState(false);
  const [img, setImg] = useState(0);

  useEffect(() => {
    if (favorites.includes(id)) {
      setSaved(true);
    } else if (saved) {
      setSaved(false);
    }
  }, [id, favorites]);

  const closeModalAction = (payload) => closeModal(payload.close);

  const getProposeSaveSearchData = (isFav = false) => {
    const realTimeUser = getUser();
    /**
     * questo dovrebbe valere solo per le specifiche
     * da salva favorito l'utente si logga prima
     */
    let proposeBkmAfterLead = { propose: total > 10 };
    // let proposeBkmAfterLead = { propose: false };
    if (realTimeUser.isAuthenticated) {
      proposeBkmAfterLead = globalSelector('proposeBkmAfterLead');
    }

    const activeNearby = globalSelector('activeNearby');
    const poiNamesList = globalSelector('poiNamesList');
    const disableProposeBkm = () => globalReducer('disableProposeBkmAfterLead');

    const proposeSaveSearchData = {
      searchData: {
        isMobile,
        isSmallMobile,
        filtersProps,
        saveSearchProps: {
          domain: siteUrl,
          mycasaDomain: userhost,
          accountDomain: account,
          authBasePrefix,
          app: 'P18',
          disableProposeBkm,
          activeNearby,
          poiNamesList,
          searchIsSaved: false,
          bookmarkData: null,
          publisherName: null,
          setForcedAuction: null,
          saveBookmarkSuccess,
          deleteBookmarkSuccess: null,
          setNewSearchFromSaveSearchModal,
          total,
          user: realTimeUser,
          doNotProposeGeneric: !proposeGeneric,
          isFromPropose: true,
          propose: {
            from: isFav ? 'AddFavouriteProperty' : 'SendEmail',
            searchType: 'UserSearch',
          },
          isFromNotRegisteredUser: !realTimeUser.isAuthenticated,
          logIn: userLogIn,
          cookiesConfig,
        },
        panelChooseBy: {
          isMobile,
          actionClicked: 'srpSaveSearch',
          pageFrom: pageType,
        },
      },
      createLoginModal,
      getUser,
      getUserLoginPayload,
      showSynchingDialog,
    };
    return {
      proposeBkmAfterLead,
      proposeSaveSearchData,
    };
  };

  const proposeSaveSearch = () => {
    const {
      proposeBkmAfterLead,
      proposeSaveSearchData,
    } = getProposeSaveSearchData(true);

    let eventLabel = 'Yes-UserSearch';

    if (proposeBkmAfterLead.propose && !searchIsSaved) {
      openProposeSaveSearchModal({
        openModalAction: openModal,
        closeModalAction,
        createProposeSaveSearchModal,
        createNewSaveSearchModal: saveSearchQb,
        createGenericAfterSpecificModal,
        createNewSaveSearchFeedbackModal,
        createFeedbackModal,
        proposeSaveSearchData,
      });
    } else {
      eventLabel = proposeBkmAfterLead.reason;
    }
    sleep(300).then(() => trackGTMEvents({
      category: 'FavouriteAndAlert',
      action: 'ProposeSaveSearch',
      label: eventLabel,
      from: 'AddFavouriteProperty',
    }));
  };

  const addFavorite = (hasWelcomeModal) => {
    globalReducer('addFavoriteArticle', {
      id,
      user: getUser(),
      onActionSuccess: hasWelcomeModal ? null : proposeSaveSearch,
    });
    sleep(300).then(() => {
      trackGTMEvents({
        category: 'FavouriteAndAlert',
        action: 'AddFavouriteProperty',
      });
      const evMore = {};

      if (leadsData.listingId) {
        evMore.ad = {
          id: leadsData.listingId,
        };
        if (leadsData.partnerId) {
          evMore.ad.idId = leadsData.partnerId;
        }
      }

      if (!isPublisherHp && !isPublisherPage && publisherSlug) {
        evMore.agency = {
          name: [publisherSlug],
        };
      }

      if (leadsData.idlPropertyType) {
        evMore.typology = leadsData.idlPropertyType;
      }

      trackTLEvents({
        evObj: {
          event: {
            type: 'view',
            name: 'savedFavourite',
          },
        },
        evMore,
      });
    });
  };

  const removeFavorite = () => {
    globalReducer('removeFavoriteArticle', {
      id,
      user,
    });
    sleep(300).then(() => trackGTMEvents({
      category: 'FavouriteAndAlert',
      action: 'RemoveFavouriteProperty',
    }));
  };

  const toggleFavorite = () => {
    if (user.isAuthenticated) {
      if (saved) {
        removeFavorite();
      } else {
        addFavorite();
      }
    } else {
      logIn((hasWelcomeModal) => {
        if (hasWelcomeModal) {
          addFavorite(hasWelcomeModal);
        } else {
          showSynchingDialog({ afterSynchFn: addFavorite });
        }
      }, 'AddFavouriteProperty');
    }
  };

  const phoneReveal = ({ isFromModal = false } = {}) => {
    if (!isFromModal) {
      const trackPosition =
        isPublisherHp || isPublisherPage ? 'AP_Card' : 'SRP_Card';

      sleep(300).then(() => trackGTMEvents({
        category: 'Lead',
        action: 'RevealPhone',
        position: trackPosition,
      }));
    }

    openModal(
      createPhonecallModal({
        phonenumber: publisherPhone,
        listingId: id,
        partnerId: leadsData.partnerId,
        idlPropertyType: leadsData.idlPropertyType,
        adRef: refPublisher,
        publisherId,
        publisherSlug,
        hasCrossSubscription,
        isProjectProfile,
      }),
    );
  };

  const startCall = async (payload) => {
    await closeModal(payload.closeModal);
    sleep(450).then(() => phoneReveal({ isFromModal: true }));
  };

  const sendMail = ({ fromGallery = false, forcedPosition } = {}) => {
    const trackPosition = forcedPosition || (isPublisherHp || isPublisherPage
      ? 'AP_Card'
      : 'SRP_Card');

    sleep(300).then(() => trackGTMEvents({
      category: 'Interaction',
      action: 'OpenContactForm',
      position: trackPosition,
    }, {}, true));

    const leadConf = {
      ...leadsData,
      user,
      isMobile,
      device,
      filters,
      locations,
      app: 'P18',
      startCall,
      justEmail: fromGallery,
      isPublisherPage: isPublisherHp || isPublisherPage,
    };

    const {
      proposeBkmAfterLead,
      proposeSaveSearchData,
    } = getProposeSaveSearchData();

    openSpecificModal({
      openModalAction: openModal,
      closeModalAction,
      createSpecificModal,
      createGenericAfterSpecificModal,
      createFeedbackModal,
      createProposeSaveSearchModal,
      createNewSaveSearchModal: saveSearchQb,
      createNewSaveSearchFeedbackModal,
      setUserFormDataAction: updateUserFormData,
      leadConf,
      proposeBkm: proposeBkmAfterLead,
      proposeSaveSearchData,
      siteUrl,
    });
  };

  const goToPDP = ({ e, unitUri = null, rightClick = false }) => {
    const activeNearby = globalSelector('activeNearby');
    const url = `${unitUri || uri}${img !== 0 ? `#img${img + 1}` : ''}`;
    loadDetail(e, url, isFillin, rightClick, activeNearby);
  };

  const actions = {
    goToPDP,
    sendMail,
    phoneReveal,
    toggleFavorite,
    setImg,
  };

  const showMortgage =
    (filters.paymentMin && filters.paymentMin !== 'all') ||
    (filters.paymentMax && filters.paymentMax !== 'all');
  const showMqPrice =
    (filters.mqpriceMin && filters.mqpriceMin !== 'all') ||
    (filters.mqpriceMax && filters.mqpriceMax !== 'all');

  return (
    <LazyLoad>
      {(visible, setRef) => (
        <SrpCard
          {...article}
          visible={visible}
          setRef={setRef}
          isSixth={isSixth}
          isFetching={isFetching}
          collapseMandateBanner={collapseMandateBanner}
          actions={actions}
          saved={saved}
          showMortgage={showMortgage}
          showMqPrice={showMqPrice}
          vWidth={viewport.width}
          iosLth12={iosLth12}
          createFloorplanModal={createFloorplanModal}
          setCurrentFloorplan={setCurrentFloorplan}
          sendMail={sendMail}
        />
      )}
    </LazyLoad>
  );
}

export default memo(Card);

Card.propTypes = {
  article: PropTypes.instanceOf(Object).isRequired,
  isSixth: PropTypes.bool,
  isFetching: PropTypes.bool,
  loadDetail: PropTypes.func,
  collapseMandateBanner: PropTypes.func,
  isFillin: PropTypes.bool,
  viewport: PropTypes.instanceOf(Object),
  userLogIn: PropTypes.func,
  setCurrentFloorplan: PropTypes.func,
};

Card.defaultProps = {
  isSixth: false,
  isFetching: false,
  loadDetail: () => { },
  collapseMandateBanner: () => { },
  isFillin: false,
  viewport: {},
  userLogIn: () => { },
  setCurrentFloorplan: () => { },
};
