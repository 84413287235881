import React from 'react';
import PropTypes from 'prop-types';

import openSpecificModal from 'shared-utils/src/leads/openSpecificModal';

import PublisherCard from 'shared-components/components/PublisherCard';

import { useCtxGlobal } from '@client/app/Container';

import { createQRCodeOnly } from '@client/components/common/DynamicLoader/qrcode';

import {  
  createPhonecallModal,
  createSpecificModal,
  createFeedbackModal,
} from '../../../../modals/factories';

import './style.scss';

const Publishers = ({ size }) => {
  const {
    openModal,
    closeModal,  
    updateUserFormData,
    isMobile,
    model: {
      fewResults: {
        isNoResults,
        publishers,
      },      
    },
    filtersProps: {
      device,
    },
    user,
    siteUrl,
  } = useCtxGlobal();

  const leadsApp = 'P18';

  if (!publishers.length) {
    return null;
  }

  const closeModalAction = payload => closeModal(payload.close);

  const openSpecificModalAction = data => openSpecificModal({
    openModalAction: openModal,
    closeModalAction,
    createSpecificModal,
    createFeedbackModal,   
    setUserFormDataAction: updateUserFormData,
    siteUrl,
    leadConf: {
      user,
      isMobile,
      device,
      app: leadsApp,
      isPublisherLead: true,
      justEmail: true,
      publisher: data,
    },
  });  

  return (
    <div className="fill_pubs" style={{ opacity: size ? 0 : null }}>
      <p className="fill_pubs__p tp-s--x2l tp-w--l c-txt--f0">
        Fatti aiutare nella tua ricerca
      </p>
      {
        publishers.map(
          (pub, n) => (
            <PublisherCard
              key={`pcard_${pub.publisherName}`}
              device={device}
              data={pub}
              lastOne={n === publishers.length - 1}
              user={user}
              leadsUtils={{
                openModal, 
                createPhonecallModal,
                openSpecificModalAction,
                app: leadsApp,
              }}
              siteUrl={siteUrl}
              createQRCodeOnly={createQRCodeOnly}
            />
          ),
        )          
      }
    </div>
  );
};

export default Publishers;

Publishers.propTypes = {
  size: PropTypes.string,
};

Publishers.defaultProps = {
  size: null,
};
