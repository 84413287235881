import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCtxGlobal } from '@client/app/Container';
import openSaveSearchModal from 'shared-utils/src/saveSearch/openSaveSearchModal';
import {
  saveSearchQb,
  createGenericAfterSpecificModal,
  createFeedbackModal,
  createNewSaveSearchFeedbackModal,
  createConsentRequestModal,
} from '@modals/factories';
import sleep from 'shared-utils/src/sleep';


const LoadSaveSearchModal = ({
  setForcedAuction,
  userLogIn,
  showSaveSearchModalFromExternal,
}) => {
  const {
    openModal,
    closeModal,
    siteUrl,
    isMobile,
    isSmallMobile,
    getSearchesList,
    constantsCopy,
    globalSelector,
    globalState: {
      config: {
        reCAPTCHA,
        cookiesConfig = {},
        api: { account, authBasePrefix },
      } = {},
    } = {},
    user,
    userConf: {
      userUrls: { userhost },
    },
    saveBookmarkSuccess,
    setNewSearchFromSaveSearchModal,
    model: { total, paginator: { currentPage } } = {},
    pageType,
    filtersProps,
  } = useCtxGlobal();

  const initModal = ({ forceEmailFrequency = '', forcePushFrequency = '' }) => {
    const { isAuthenticated } = user;
    const activeNearby = globalSelector('activeNearby');
    const poiNamesList = globalSelector('poiNamesList');
    const searchIsSaved = globalSelector('searchIsSaved');
    // const firstCallForSaveSearchFinished = globalSelector('firstCallForSaveSearchFinished');
    const bookmarkData = globalSelector('bookmarkData');
    const disableProposeBkm = () => globalReducer('disableProposeBkmAfterLead');
    const closeModalAction = payload => closeModal(payload.close);
    const deleteBookmarkSuccess = () => {
      globalReducer('deleteBookmark', null);
      // checkProposeBkmAfterLead();
    };
    openSaveSearchModal({
      openModalAction: openModal,
      closeModalAction,
      createNewSaveSearchModal: saveSearchQb,
      createGenericAfterSpecificModal,
      createNewSaveSearchFeedbackModal,
      createConsentRequestModal,
      createFeedbackModal,
      getSearchesList,
      isMobile,
      isSmallMobile,
      filtersProps,
      copyModalTitle: constantsCopy,
      saveSearchProps: {
        domain: siteUrl,
        mycasaDomain: userhost,
        accountDomain: account,
        portalDomain: siteUrl,
        authBasePrefix,
        app: 'P18',
        disableProposeBkm,
        activeNearby,
        poiNamesList,
        searchIsSaved,
        bookmarkData,
        setForcedAuction,
        saveBookmarkSuccess,
        deleteBookmarkSuccess,
        setNewSearchFromSaveSearchModal,
        total,
        isFromNotRegisteredUser: !isAuthenticated,
        user,
        logIn: userLogIn,
        reCAPTCHA,
        cookiesConfig,
        ...forceEmailFrequency && { forceEmailFrequency },
        ...forcePushFrequency && { forcePushFrequency },
        // positionGTM,
        // ...getProposeDetails(),
      },
      panelChooseBy: {
        isMobile,
        actionClicked: 'srpSaveSearch',
        pageFrom: pageType,
      },
    });
  };

  useEffect(() => {

    switch (showSaveSearchModalFromExternal) {
      case 'fromMyCasaBkm':
        if (user.isAuthenticated) {
          sleep(1000).then(() => {
            initModal({});
          });
        }
        break;
      default:
        break;
    }
  }, [user.isAuthenticated]);

  useEffect(() => {

    switch (showSaveSearchModalFromExternal) {
      case 'fromGenericAlert':
        sleep(2000).then(() => {
          initModal({
            forceEmailFrequency: 'daily',
            forcePushFrequency: 'daily',
          });
        });
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
    </>
  );
};

LoadSaveSearchModal.propTypes = {
  setForcedAuction: PropTypes.func,
  userLogIn: PropTypes.func,
  showSaveSearchModalFromExternal: PropTypes.string,
};

LoadSaveSearchModal.defaultProps = {
  setForcedAuction: () => { },
  userLogIn: () => { },
  showSaveSearchModalFromExternal: '',
};
export default LoadSaveSearchModal;
