import React from 'react';
import PropTypes from 'prop-types';
import makeClassName from 'shared-utils/src/makeClassName/index';
import CloseL from 'shared-vectors/icons/CloseL';
import { Icon } from 'shared-vectors';
import Grid from 'shared-components/components/Grid';

import './style.scss';

const HintTooltip = ({
  visible:
  vis,
  title,
  subTitle,
  hideTooltip,
}) => {
  const mainCls = 'hint-tooltip-save-search-btn';

  const toolTipCls = makeClassName([
    mainCls,
    vis && `${mainCls}__visible`,
  ]);
  // const handleClickOutside = (event) => {
  //   if (isVisible && !event.target.closest(`.${mainCls}`)) {
  //     hideTooltip();
  //   }
  // };

  // useEffect(() => {
  //   if (isVisible) {
  //     window.addEventListener('click', handleClickOutside);
  //   }
  //   return () => {
  //     window.removeEventListener('click', handleClickOutside);
  //   };
  // }, [isVisible]);
  return (
    <Grid className={toolTipCls} align="center" justify="center">
      <Grid
        align="center"
        justify="space-between"
        className={`${mainCls}__header p--m`}
      >
        <p className="tp-w--l tp-s--l c-txt--f0">{title}</p>
        <p className="tp-w--s tp-s--m c-txt--f0">
          &nbsp;{subTitle}
        </p>
        <div
          onClick={() => hideTooltip({ position: 'CloseButton' })}
          className={`${mainCls}__header_cnt ml--s`}
        >
          <Icon
            className={`${mainCls}__header_close`}
            name={CloseL}
            width="12px"
            height="12px"
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default HintTooltip;

HintTooltip.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  hideTooltip: PropTypes.func,
};

HintTooltip.defaultProps = {
  visible: false,
  title: '',
  subTitle: '',
  hideTooltip: () => { },
};
