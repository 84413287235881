import { DEFAULT_FILTERS_CONFIGURATION } from 'shared-constants/src/filters';
import { isValidVar } from 'shared-utils/src/checkVarType';

import isEqual from '@lodash/isEqual';

import {
  getProvinceShortName,
} from '../../../client/helpers/search/Search';

import {
  appWithNRooms,
} from '../constants';

const extractL11Data = (location, allLocations) => {
  const min = 0;
  const max = allLocations.length - 1;
  const randomBlockIndex = Math.floor(Math.random() * (max - min + 1) + min);
  let regione = location.parents.filter(el => el.level === 4);
  regione = regione.length > 0 ? regione[0].id.substr(3) : '';
  let provincia = location.parents.filter(el => el.level === 6);
  provincia = getProvinceShortName(provincia[0].name);
  const comuneObj = location.parents.filter(el => el.level === 9);
  const comune = `C_${comuneObj[0].id.substr(7)}`;
  let zone = location.parents.filter(el => el.level === 10);
  zone = zone[0]?.id?.replace(/-/, '_') || '';

  let block = '';
  if (allLocations.length > 1) {
    block = allLocations[randomBlockIndex].id.replace(/-/, '_');
  } else {
    block = location.id.replace(/-/, '_');
  }
  return {
    regione,
    provincia,
    comune,
    zone,
    block,
  };
};

const extractL10Data = (location, allLocations) => {
  const min = 0;
  const max = allLocations.length - 1;
  const randomZoneIndex = Math.floor(Math.random() * (max - min + 1) + min);
  let regione = location.parents.filter(el => el.level === 4);
  regione = regione.length > 0 ? regione[0].id.substr(3) : '';
  let provincia = location.parents.filter(el => el.level === 6);
  provincia = getProvinceShortName(provincia[0].name);
  const comuneObj = location.parents.filter(el => el.level === 9);
  const comune = `C_${comuneObj[0].id.substr(7)}`;
  let zone = '';
  if (allLocations.length > 1) {
    zone = allLocations[randomZoneIndex].id.replace(/-/, '_');
  } else {
    zone = location.id.replace(/-/, '_');
  }
  return {
    regione,
    provincia,
    comune,
    zone,
  };
};

const extractL09Data = (location) => {
  let regione = location.parents.filter(el => el.level === 4);
  regione = regione.length > 0 ? regione[0].id.substr(3) : '';
  let provincia = location.parents.filter(el => el.level === 6);
  provincia = getProvinceShortName(provincia[0].name);
  const comune = `C_${location.id.substr(7)}`;
  return {
    regione,
    provincia,
    comune,
    zone: '',
  };
};

const extractL06Data = (location) => {
  let regione = location.parents.filter(el => el.level === 4);
  regione = regione.length > 0 ? regione[0].id.substr(3) : '';
  const provincia = getProvinceShortName(location.name);
  return {
    regione,
    provincia,
    comune: '',
    zone: '',
  };
};

const extractL04Data = (location) => {
  const regione = location.id.substr(3);
  return {
    regione,
    provincia: '',
    comune: '',
    zone: '',
  };
};

const gptStrip2AllowedProvinces = (locations, advConfig) => {
  let toreturn = JSON.parse(JSON.stringify(advConfig));
  for (let i = 0; i < locations.length; i += 1) {
    const loc = locations[i];
    if (
      'level' in loc
      && loc.level > 4
    ) {
      if (loc.level === 6) {
        if (
          loc.slug === 'milano'
          || loc.slug === 'monza-e-brianza'
        ) {
          toreturn = JSON.parse(JSON.stringify(advConfig));
        } else {
          toreturn = JSON.parse(JSON.stringify(advConfig));
          delete toreturn.stripmobile2;
          break;
        }
      } else if (
        loc.parent.province.slug === 'milano'
          || loc.parent.province.slug === 'monza-e-brianza'
      ) {
        toreturn = JSON.parse(JSON.stringify(advConfig));
      } else {
        toreturn = JSON.parse(JSON.stringify(advConfig));
        delete toreturn.stripmobile2;
        break;
      }
    } else {
      toreturn = JSON.parse(JSON.stringify(advConfig));
      delete toreturn.stripmobile2;
      break;
    }
  }
  return toreturn;
};

export const computeListingGTPData = (selectedState, config) => {
  const { queryFilters } = selectedState;

  let channel = 'tr' in queryFilters && queryFilters.tr === 'affitti' ? 'affitti' : 'vendita';
  const { propertyTypeGroup } = queryFilters;
  const apiLHSuggest = config.apiLHSuggest || (config.smartSuggest && config.smartSuggest.uri) || '';

  let category = 'residenziale';
  if (propertyTypeGroup === 'commerciale') {
    category = propertyTypeGroup;
  } else if (propertyTypeGroup === 'case-vacanza') {
    channel = 'affitti';
    category = 'vacanze';
  }

  let ptypeCategory = 'case';
  if (propertyTypeGroup) {
    ptypeCategory = propertyTypeGroup;
  }
  if (
    config.env === 'dev'
    || (
      apiLHSuggest
      && (
        apiLHSuggest.indexOf('dev') > -1
        || apiLHSuggest.indexOf('stg') > -1
      )
    )
  ) {
    category = 'betaresidenziale';
  }
  const gptSlot = 'publisher' in selectedState ? config.layout.srp.adv.slots.agenzie : config.layout.srp.adv.slots[channel];
  const gptSection = config.layout.srp.adv.siteSection;
  const advConfigWithProvinceCondition = gptStrip2AllowedProvinces(selectedState.resolvedLocations, config.layout.srp.adv.positions);
  const positions = Object.keys(advConfigWithProvinceCondition).join();
  const positionsConf = advConfigWithProvinceCondition;

  let gptPrice = '';
  let firstAvailablePrice = '';
  for (let i = 0; i < selectedState.list.length; i += 1) {
    const item = selectedState.list[i];
    if (
      item.features.price.show
      && item.features.price.value !== null
    ) {
      let cleanValue = item.features.price.value;
      if (typeof cleanValue === 'string') {
        
        cleanValue = parseInt(cleanValue.replace(/\./g, ''), 10);
      }
      firstAvailablePrice = cleanValue;      
      break;
    }
  }

  if (channel === 'affitti') {
    if (firstAvailablePrice > 0 && firstAvailablePrice < 150) {
      gptPrice = '0_150pw';
    } else if (firstAvailablePrice >= 150 && firstAvailablePrice < 250) {
      gptPrice = '150pw_250pw';
    } else if (firstAvailablePrice >= 250 && firstAvailablePrice < 350) {
      gptPrice = '250pw_350pw';
    } else if (firstAvailablePrice >= 350 && firstAvailablePrice < 450) {
      gptPrice = '350pw_450pw';
    } else if (firstAvailablePrice >= 450 && firstAvailablePrice < 600) {
      gptPrice = '450pw_600pw';
    } else if (firstAvailablePrice >= 600 && firstAvailablePrice < 750) {
      gptPrice = '600pw_750pw';
    } else if (firstAvailablePrice >= 750 && firstAvailablePrice < 1000) {
      gptPrice = '750pw_1000pw';
    } else if (firstAvailablePrice >= 1000 && firstAvailablePrice < 2000) {
      gptPrice = '1000pw_2000pw';
    } else if (firstAvailablePrice >= 2000) {
      gptPrice = '2000pw';
    }
  } else if (channel === 'vendita') {
    firstAvailablePrice /= 1000;
    if (firstAvailablePrice > 0 && firstAvailablePrice < 100) {
      gptPrice = '0_100k';
    } else if (firstAvailablePrice >= 100 && firstAvailablePrice < 200) {
      gptPrice = '100k_200k';
    } else if (firstAvailablePrice >= 200 && firstAvailablePrice < 300) {
      gptPrice = '200k_300k';
    } else if (firstAvailablePrice >= 300 && firstAvailablePrice < 400) {
      gptPrice = '300k_400k';
    } else if (firstAvailablePrice >= 400 && firstAvailablePrice < 500) {
      gptPrice = '400k_500k';
    } else if (firstAvailablePrice >= 500 && firstAvailablePrice < 600) {
      gptPrice = '500k_600k';
    } else if (firstAvailablePrice >= 600 && firstAvailablePrice < 750) {
      gptPrice = '600k_750k';
    } else if (firstAvailablePrice >= 750 && firstAvailablePrice < 1000) {
      gptPrice = '750k_1m';
    } else if (firstAvailablePrice >= 1000 && firstAvailablePrice < 3000) {
      gptPrice = '1m_3m';
    } else if (firstAvailablePrice >= 3000 && firstAvailablePrice < 5000) {
      gptPrice = '3m_5m';
    } else if (firstAvailablePrice >= 5000) {
      gptPrice = '5m';
    }
  }
  let gptGeoData = {
    regione: '',
    provincia: '',
    comune: '',
    zone: '',
    state: '',
  };
  if (selectedState.resolvedLocations.length > 0) {
    const lastAvailableLocation = selectedState.resolvedLocations[selectedState.resolvedLocations.length - 1];
    switch (lastAvailableLocation.level) {
      case 11:
        gptGeoData = extractL11Data(lastAvailableLocation, selectedState.resolvedLocations);
        break;
      case 10:
        gptGeoData = extractL10Data(lastAvailableLocation, selectedState.resolvedLocations);
        break;
      case 9:
        gptGeoData = extractL09Data(lastAvailableLocation);
        break;
      case 6:
        gptGeoData = extractL06Data(lastAvailableLocation);
        break;
      case 4:
        gptGeoData = extractL04Data(lastAvailableLocation);
        break;
      default:
    }
  }

  if (
    'zones' in queryFilters
    && queryFilters.zones !== ''
    && gptGeoData.zone === ''
  ) {
    const zones = queryFilters.zones.replace(/-/g, '_');
    gptGeoData.zone = zones;
  }

  gptGeoData.state = gptGeoData.regione;

  // fix bibione
  if (gptGeoData.comune === 'C_028108') {
    gptGeoData.comune = 'C_28108';
  }

  let gptAgent = null;
  if ('publisher' in selectedState) {
    gptAgent = selectedState.publisher.id;
  }

  let gptPropertyTypes = null;
  if ('propertyTypes' in queryFilters) {
    const filterValues = DEFAULT_FILTERS_CONFIGURATION.propertyTypes.values[ptypeCategory];
    const pTypeLabels = [];
    const qfPtypes = typeof queryFilters.propertyTypes === 'string' ? queryFilters.propertyTypes.split(',') : queryFilters.propertyTypes;
    qfPtypes.forEach(
      (slug) => {
        const match = slug.replace(/ /g, '+');
        const matchCondition = filterValues.filter(filterConf => filterConf.slug === match)[0];
        if (typeof matchCondition !== 'undefined') {
          pTypeLabels.push(matchCondition.display);
        }
      },
    );
    gptPropertyTypes = pTypeLabels;
  }

  if ('propertyTypeGroup' in queryFilters) {
    if (queryFilters.propertyTypeGroup === 'garage-posti-auto') {
      if (gptPropertyTypes) {
        gptPropertyTypes.push('Garage');
      } else {
        gptPropertyTypes = ['Garage'];
      }
    } else if (queryFilters.propertyTypeGroup === 'nuove-costruzioni') {
      if (gptPropertyTypes) {
        gptPropertyTypes.push('Nuova costruzione');
      } else {
        gptPropertyTypes = ['Nuova costruzione'];
      }
    }
  }

  if (
    'numRoomsMin' in queryFilters
    && 'numRoomsMax' in queryFilters
    && queryFilters.numRoomsMin === queryFilters.numRoomsMax
    && queryFilters.numRoomsMin > 0
    && queryFilters.numRoomsMin < 5
  ) {
    if (gptPropertyTypes) {
      gptPropertyTypes.push(appWithNRooms[queryFilters.numRoomsMin]);
    } else {
      gptPropertyTypes = [appWithNRooms[queryFilters.numRoomsMin]];
    }
  }

  let gptBuildingCondition = null;
  if (
    'buildingCondition' in queryFilters
  ) {
    gptBuildingCondition = [];
    const values = queryFilters.buildingCondition.split(',');
    if (values.indexOf('da-ristrutturare') > -1) {
      gptBuildingCondition.push('da-ristrutturare');
    }
    if (values.indexOf('nuovo-ristrutturato') > -1) {
      gptBuildingCondition.push('nuovo');
    }
  }

  let nearSchools = false;
  if (
    queryFilters.poi
    && (
      Object.keys(queryFilters.poi).indexOf('nursery_school') > -1
      || Object.keys(queryFilters.poi).indexOf('elementary_school') > -1
      || Object.keys(queryFilters.poi).indexOf('secondary_school') > -1
      || Object.keys(queryFilters.poi).indexOf('high_school') > -1
    )
  ) {
    nearSchools = true;
  }
  let gptFeatures = null;
  if (
    'garden' in queryFilters
    || 'terrace' in queryFilters
    || 'balcony' in queryFilters
    || 'numParkingSpaces' in queryFilters
    || nearSchools
  ) {
    gptFeatures = [];
    if ('garden' in queryFilters) {
      gptFeatures.push('garden');
    }
    if ('numParkingSpaces' in queryFilters) {
      gptFeatures.push('garage');
    }
    if (
      'terrace' in queryFilters
      || 'balcony' in queryFilters
    ) {
      gptFeatures.push('balcony_terrace');
    }
    if (nearSchools) {
      gptFeatures.push('nearschools');
    }
  }

  return {
    channel,
    category,
    gptSlot,
    gptSection,
    positions,
    positionsConf,
    gptPrice,
    gptGeoData,
    gptAgent,
    gptPropertyTypes,
    gptBuildingCondition,
    gptFeatures,
  };
};

export const refreshGPTTags = (nextSearch, nextConfig) => {
  const {
    gptSlot,
    channel: gptChannel,
    category: gptCategory,
    gptPrice,
    gptGeoData: {
      regione,
      provincia,
      comune,
      zone,
      block,
    },
    gptSection,
    gptPropertyTypes,
    gptBuildingCondition,
    gptFeatures,
  } = computeListingGTPData(nextSearch, nextConfig);
  if (
    window.googletag
    && window.googletag.apiReady
    && window.googletag.pubadsReady
    && window.googletag.pubads
    && (
      window.gptRefreshValues.regione !== regione
      || window.gptRefreshValues.provincia !== provincia
      || window.gptRefreshValues.comune !== comune
      || window.gptRefreshValues.zone !== zone
      || window.gptRefreshValues.block !== block
      || window.gptRefreshValues.gptPrice !== gptPrice
      || !isEqual(window.gptRefreshValues.gptPropertyTypes, gptPropertyTypes)
      || window.gptRefreshValues.category !== gptCategory
      || window.gptRefreshValues.channel !== gptChannel
      || window.gptRefreshValues.buildingCondition !== gptBuildingCondition
      || !isEqual(window.gptRefreshValues.features, gptFeatures)
    )
  ) {
    const tipologia = isValidVar(gptPropertyTypes) && gptPropertyTypes !== 'null'
      ? gptPropertyTypes
      : null;
    const condizioniImmobile = isValidVar(gptBuildingCondition) && gptBuildingCondition !== 'null'
      ? gptBuildingCondition
      : null;
    const features = isValidVar(gptFeatures) && gptFeatures !== 'null'
      ? gptFeatures
      : null;
    window.googletag.destroySlots();
    window.googletag.cmd.push(() => {
      for (let n = 0; n < window.dfpVars.positions.length; n += 1) {
        const ord = new Date().getTime();
        const pos = window.dfpVars.positions[n];
        const conf = window.dfpVars.positionConfigurations[pos];
        const sizes = [];
        for (let i = 0; i < conf.sizes.length; i += 1) {
          sizes.push([conf.sizes[i].width, conf.sizes[i].height]);
        }
        gptSlots[n] = window.googletag.defineSlot(gptSlot, sizes, pos).addService(window.googletag.pubads());
        for (const target in conf.targets) {
          if (conf.targets.hasOwnProperty(target)) {
            gptSlots[n].setTargeting(target, [conf.targets[target]]);
          }
        }
        gptSlots[n].setTargeting('ord', [ord]);
        gptSlots[n].setTargeting('regione', [regione]);
        gptSlots[n].setTargeting('provincia', [provincia]);
        gptSlots[n].setTargeting('comune', [comune]);
        gptSlots[n].setTargeting('zone', [zone]);
        gptSlots[n].setTargeting('Quartiere', [block]);
        gptSlots[n].setTargeting('price', [gptPrice]);
        if (tipologia !== null) {
          gptSlots[n].setTargeting('Tipologia', tipologia);
        }
        if (condizioniImmobile !== null) {
          gptSlots[n].setTargeting('condizioniImmobile', [condizioniImmobile]);
        }
        if (features !== null) {
          gptSlots[n].setTargeting('features', features);
        }
        gptSlots[n].setTargeting('sect', [gptSection]);
        gptSlots[n].setTargeting('channel', [gptChannel]);
        gptSlots[n].setTargeting('type', [gptCategory]);

      }
      window.googletag.pubads().refresh();
    });

    window.gptRefreshValues.channel = gptChannel;
    window.gptRefreshValues.category = gptCategory;
    window.gptRefreshValues.regione = regione;
    window.gptRefreshValues.provincia = provincia;
    window.gptRefreshValues.comune = comune;
    window.gptRefreshValues.zone = zone;
    window.gptRefreshValues.block = block;
    window.gptRefreshValues.gptPrice = gptPrice;
    window.gptRefreshValues.gptPropertyTypes = tipologia || null;
    window.gptRefreshValues.buildingCondition = condizioniImmobile || null;
  }
}