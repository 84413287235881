import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TagsContainer from 'shared-components/components/TagsContainer';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import resetTempFilter from 'shared-utils/src/resetTempFilter';
import '../critical.scss';
import './style.scss';
import { useCtxGlobal } from '@client/app/Container';
import EditSrp from 'shared-vectors/icons/EditSrp';
import { Icon } from 'shared-vectors';
import { getActiveFilters } from '@filters/helpers';
import { getHumanFilters } from '@helpers/search/Search';
import TitleAndSaveButton from './TitleAndSaveButton';

const BoxHeaderSrp = ({
  openQueryBuilder,
  title,
  h2,
  aliasH2,
  count,
  filters,
  updateFilters,
  setForcedAuction,
  checkProposeBkmAfterLead,
  userLogIn,
  publisherName,
  showSaveSearchButton,
  showSaveSearchBannerGreenHint,
  fewResults,
}) => {
  const { activeFiltersCount, activeFiltersList } = getActiveFilters(filters);
  const [filtersList, setFiltersList] = useState(
    activeFiltersCount ? getHumanFilters(activeFiltersList) : [],
  );

  const { 
    isMobile,
    constantsCopy: {
      SRP_SAVE_SEARCH_BTN_SAVE_SEARCH_BOX,
      SRP_EDIT_SEARCH_BTN_SAVE_SEARCH_BOX,
      SRP_SAVE_SEARCH_TOOLTIP_BTN_SAVE_SEARCH_MAIN,
      SRP_SAVE_SEARCH_TOOLTIP_BTN_SAVE_SEARCH_DESC,
    },
  } = useCtxGlobal();
  useEffect(() => {
    setFiltersList(
      activeFiltersCount ? getHumanFilters(activeFiltersList) : [],
    );
  }, [filters]);

  const preResetTempFilter = (name) => {
    trackGTMEvents(
      {
        category: 'PushButton',
        action: 'DeleteFilter',
        label: name,
        position: 'SRP_TitleArea',
      },
      {},
      true,
    );
    resetTempFilter({
      filterName: name,
      filtersList,
      setFiltersList,
      filters,
      updateFilters,
    });
  };

  return (
    <div className="box-header-srp">
      <div className="srp-hdr" onClick={isMobile ? openQueryBuilder : null}>
        <TitleAndSaveButton
          title={title}
          h2={h2}
          aliasH2={aliasH2}
          publisherName={publisherName}
          setForcedAuction={setForcedAuction}
          checkProposeBkmAfterLead={checkProposeBkmAfterLead}
          userLogIn={userLogIn}
          count={count}
          showSaveSearchButton={showSaveSearchButton}
          showSaveSearchBannerGreenHint={showSaveSearchBannerGreenHint}
          fewResults={fewResults}
          copySaveSearch={{
            title: SRP_SAVE_SEARCH_BTN_SAVE_SEARCH_BOX,
            editTitle: SRP_EDIT_SEARCH_BTN_SAVE_SEARCH_BOX,
            tooltipMain: SRP_SAVE_SEARCH_TOOLTIP_BTN_SAVE_SEARCH_MAIN,
            tooltipDesc: SRP_SAVE_SEARCH_TOOLTIP_BTN_SAVE_SEARCH_DESC,
          }}


        />
        {filtersList.length > 0 && (
          <TagsContainer
            list={filtersList}
            actionToPerform={preResetTempFilter}
            isMobile={isMobile}
            notShownOn="mobile"
          />
        )}
        {isMobile && (
          <Icon
            className="srp-hdr__icon"
            name={EditSrp}
            width="24px"
            height="24px"
          />
        )}
      </div>
    </div>
  );
};

BoxHeaderSrp.propTypes = {
  openQueryBuilder: PropTypes.func,
  title: PropTypes.string,
  h2: PropTypes.string,
  aliasH2: PropTypes.string,
  count: PropTypes.string,
  filters: PropTypes.object,
  updateFilters: PropTypes.func,
  setForcedAuction: PropTypes.func,
  checkProposeBkmAfterLead: PropTypes.func,
  userLogIn: PropTypes.func,
  publisherName: PropTypes.string,
  showSaveSearchButton: PropTypes.bool,
  showSaveSearchBannerGreenHint: PropTypes.bool,
  fewResults: PropTypes.object,
};
BoxHeaderSrp.defaultProps = {
  openQueryBuilder: () => {},
  title: '',
  h2: '',
  aliasH2: '',
  count: 0,
  filters: {},
  updateFilters: () => {},
  setForcedAuction: () => {},
  checkProposeBkmAfterLead: () => {},
  userLogIn: () => {},
  publisherName: null,
  showSaveSearchButton: true,
  showSaveSearchBannerGreenHint: false,
  fewResults: {},
};

export default BoxHeaderSrp;
