import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AlarmIcon from 'shared-vectors/icons/BellFilled';
import NoAlarmIcon from 'shared-vectors/icons/BellFilledOff';

import StaredIcon from 'shared-vectors/icons/Stared';
import StarIcon from 'shared-vectors/icons/Star';

import { setItem, getItem } from 'shared-utils/src/user/helpers';
import Grid from 'shared-components/components/Grid';

import { Icon } from 'shared-vectors';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import trackTLEvents from 'shared-utils/src/trackTLEvents';
import openSaveSearchModal from 'shared-utils/src/saveSearch/openSaveSearchModal';
import sleep from 'shared-utils/src/sleep';
import loadSearchIconDynamically from 'shared-utils/src/loadSearchIconDynamically';
import { useCtxGlobal } from '@client/app/Container';
import {
  saveSearchQb,
  createGenericAfterSpecificModal,
  createFeedbackModal,
  createNewSaveSearchFeedbackModal,
  createConsentRequestModal,
} from '@modals/factories';

import './style.scss';
import HintTooltip from './HintTooltip';

const SaveSearchButton = ({
  publisherName,
  setForcedAuction,
  checkProposeBkmAfterLead,
  userLogIn,
  notShownOn,
  positionGTM,
  showSaveSearchBannerGreenHint: cmpLoaded,
  copySaveSearch: {
    title,
    editTitle,
  },
}) => {
  const {
    openModal,
    closeModal,
    globalState: {
      config: {
        reCAPTCHA,
        cookiesConfig = {},
        api: { account, authBasePrefix },
      } = {},
    } = {},
    globalSelector,
    globalReducer,
    isMobile,
    siteUrl,
    saveBookmarkSuccess,
    filtersProps,
    isSmallMobile,
    setNewSearchFromSaveSearchModal,
    pageType,
    model: { total, paginator: { currentPage } } = {},
    user,
    userConf: {
      userUrls: { userhost },
    },
    getSearchesList,
    constantsCopy,
    constantsCopy: {
      SRP_EDIT_SEARCH_NO_ALARM_ICON: iconEditNoAlarm,
      SRP_EDIT_SEARCH_ICON: iconEditAlarm,
      SRP_SAVE_SEARCH_ICON: iconSave,
      SRP_TOOLTIP_BTN_SAVE_SEARCH_MAIN,
      SRP_TOOLTIP_BTN_SAVE_SEARCH_DESCR,
    },
  } = useCtxGlobal();
  const searchIsSaved = globalSelector('searchIsSaved');
  const firstCallForSaveSearchFinished = globalSelector('firstCallForSaveSearchFinished');
  const bookmarkData = globalSelector('bookmarkData');
  const hasAlert = bookmarkData &&
    bookmarkData?.alerts?.some(a => a.alertFrequency !== 'never');

  
  
  const clsNotShowOn = notShownOn === 'none' ? '' : `not-show-on-${notShownOn}`;
  const [showTooltip, setShowTooltip] = useState(false);
  const [titleHint, setTitleHint] = useState(SRP_TOOLTIP_BTN_SAVE_SEARCH_MAIN);
  const [subTitleHint, setSubTitleHint] = useState(
    SRP_TOOLTIP_BTN_SAVE_SEARCH_DESCR,
  );
  useEffect(() => {
    const st = getItem('hintTooltipSaveSearch');
    if (
      !isMobile &&
      cmpLoaded &&
      firstCallForSaveSearchFinished
    ) {
      try {
        const twelveHoursInMS = 12 * 60 * 60 * 1000; // 12 ore in millisecondi
        const twelveHourseAgo = Date.now() - twelveHoursInMS;
        const timeSaved = parseInt(st, 10) || 0;
        const isLessThan12Hours = twelveHourseAgo > timeSaved;
        if (!searchIsSaved && st === 'fromProposeSaveSearch' && total > 0) {
          setTitleHint('Attiva le notifiche');
          setSubTitleHint('e non perderti nessuna occasione!');
          setShowTooltip(true);
        } else if (
          isLessThan12Hours &&
          currentPage === 1 &&
          total > 0 &&
          total <= 500
        ) {
          if (searchIsSaved) {
            const ct = Date.now();
            setItem('hintTooltipSaveSearch', ct);
            setShowTooltip(false);
          } else {
            setShowTooltip(true);
            sleep(300).then(() => trackGTMEvents({
              category: 'FavouriteAndAlert',
              action: 'ShowSaveSearchBanner',
            }, {}, true));
          }
        }
      } catch (error) {
        setShowTooltip(true);
      }
    } else if (isMobile) {
      setShowTooltip(false);
    }
  }, [cmpLoaded, total, isMobile, searchIsSaved, firstCallForSaveSearchFinished]);

  const getProposeDetails = () => {

    const proposeBkmBaloon = globalSelector('proposeBkmBaloon');
    const isFromPropose = !!proposeBkmBaloon;
    const propose = isFromPropose
      ? {
        from: proposeBkmBaloon.from,
        searchType: ['fromProposeServer', 'exactSearch'].includes(
          proposeBkmBaloon.value,
        )
          ? 'UserSearch'
          : 'GenericSearch',
      }
      : {};

    return { isFromPropose, propose };
  };

  const hideTooltipAndSetLS = () => {
    const ct = Date.now();
    setShowTooltip(false);
    setItem('hintTooltipSaveSearch', ct);
  }

  const saveSearch = () => {
    const { isAuthenticated } = user;
    const activeNearby = globalSelector('activeNearby');
    const poiNamesList = globalSelector('poiNamesList');
    const ct = Date.now();
    setShowTooltip(false);
    setItem('hintTooltipSaveSearch', ct);
    const deleteBookmarkSuccess = () => {
      globalReducer('deleteBookmark', null);
      checkProposeBkmAfterLead();
    };
    const disableProposeBkm = () => globalReducer('disableProposeBkmAfterLead');
    const closeModalAction = payload => closeModal(payload.close);
    openSaveSearchModal({
      openModalAction: openModal,
      closeModalAction,
      createNewSaveSearchModal: saveSearchQb,
      createGenericAfterSpecificModal,
      createNewSaveSearchFeedbackModal,
      createConsentRequestModal,
      createFeedbackModal,
      getSearchesList,
      isMobile,
      isSmallMobile,
      filtersProps,
      copyModalTitle: constantsCopy,
      saveSearchProps: {
        domain: siteUrl,
        mycasaDomain: userhost,
        accountDomain: account,
        portalDomain: siteUrl,
        authBasePrefix,
        app: 'P18',
        disableProposeBkm,
        activeNearby,
        poiNamesList,
        searchIsSaved,
        bookmarkData,
        publisherName,
        setForcedAuction,
        saveBookmarkSuccess,
        deleteBookmarkSuccess,
        setNewSearchFromSaveSearchModal,
        total,
        isFromNotRegisteredUser: !isAuthenticated,
        user,
        logIn: userLogIn,
        reCAPTCHA,
        cookiesConfig,
        positionGTM,
        ...getProposeDetails(),
      },
      panelChooseBy: {
        isMobile,
        actionClicked: 'srpSaveSearch',
        pageFrom: pageType,
      },
    });
  };

  const saveSearchEventLabel = () => {
    if (!bookmarkData) {
      return 'notSavedYet';
    }
    const { alerts } = bookmarkData;
    if (
      !alerts.mail ||
      alerts.mail.alertFrequency === 'never' ||
      !alerts.push ||
      alerts.push.alertFrequency === 'never'
    ) {
      return 'isFavourite';
    }
    return 'hasAlert';
  };

  const saveSearchProxy = (e) => {
    e.preventDefault();
    // const { searchIsSaved, bookmarkData } = getSearchDetails();
    const { isFromPropose, propose } = getProposeDetails();

    const evt = {
      category: 'FavouriteAndAlert',
      action: 'OpenAlertModal',
      position: positionGTM,
      label:
        isFromPropose || !searchIsSaved
          ? 'notSavedYet'
          : saveSearchEventLabel(bookmarkData),
    };

    if (isFromPropose) {
      evt.from = propose.from;
    }

    trackGTMEvents(evt, {}, true);
    trackTLEvents({
      evObj: {
        event: {
          type: 'view',
          name: 'saveSearch',
        },
      },
    });
    saveSearch();
  };

  const iconMap = {
    Alarm: AlarmIcon,
    NoAlarm: NoAlarmIcon,
    AlarmEmpty: AlarmIcon,
    Star: StarIcon,
    Stared: StaredIcon,
  };
  const { iconName } = loadSearchIconDynamically({
    iconEditNoAlarm,
    iconSave,
    iconEditAlarm,
    hasAlerts: hasAlert,
    searchIsSaved,
  });


  return (
    <div className={`btn-save-search ${clsNotShowOn}`}>
      {searchIsSaved ? (
        <button
          type="button"
          aria-label="Salva la ricerca"
          className="btn-save-search-saved c-bg--w"
          data-action="saveSearch"
          onClick={saveSearchProxy}
        >
          <Grid
            className={`btn-save-search-cnt-icon${!hasAlert ? '-no-alarm' : ''}`}
            align="center"
            justify="center"
          >
            <Icon name={iconMap[iconName]} width="24px" fill />
          </Grid>

          <span className="text tp-s--l tp-w--m c-txt--f0 ml--xs">
            {editTitle}
          </span>
        </button>
      ) : (
        <button
          type="button"
          aria-label="Salva la ricerca"
          className="save"
          data-action="saveSearch"
          onClick={saveSearchProxy}
        >
          <Grid
            className="btn-save-search-cnt-icon"
            align="center"
            justify="center"
          >
            <Icon name={iconMap[iconName]} width="24px" fill />
          </Grid>
          <span className="tp-w--l tp-s--l ml--xs">{title}</span>
        </button>
      )}
      {showTooltip && (
        <HintTooltip
          visible={!isMobile}
          title={titleHint}
          subTitle={subTitleHint}
          hideTooltip={hideTooltipAndSetLS}
        />
      )}
    </div>
  );
};

SaveSearchButton.propTypes = {
  publisherName: PropTypes.string,
  setForcedAuction: PropTypes.func,
  checkProposeBkmAfterLead: PropTypes.func,
  userLogIn: PropTypes.func,
  notShownOn: PropTypes.string,
  positionGTM: PropTypes.string,
  showSaveSearchBannerGreenHint: PropTypes.bool,
  copySaveSearch: PropTypes.object,
};

SaveSearchButton.defaultProps = {
  publisherName: '',
  setForcedAuction: () => { },
  checkProposeBkmAfterLead: () => { },
  userLogIn: () => { },
  notShownOn: 'none',
  positionGTM: '',
  showSaveSearchBannerGreenHint: false,
  copySaveSearch: {},
};

export default SaveSearchButton;
