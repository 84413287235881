import React from 'react';
import PropTypes from 'prop-types';
import PinMap from 'shared-vectors/icons/PinMap';
import { Icon } from 'shared-vectors';
import './style.scss';
import { useCtxGlobal } from 'client/app/Container';

const GoToMapButton = ({ goToMap, clsBtn }) => {
  const { isMobile } = useCtxGlobal();
  const clsMainBtn = 'map';
  // const clsDisabled = `${!mapLinkAvailable ? `${clsMainBtn}__disabled` : ''}`;
  const clsWithImg = !isMobile ? '__with-img-map' : ''; 
  const textCls = `${clsMainBtn}${clsWithImg}__text`;
  return (
    <button
      type="button"
      // disabled={!mapLinkAvailable}
      aria-label="Vai alla ricerca su mappa"
      className={`${clsMainBtn} ${clsMainBtn}${clsWithImg} ${clsBtn || ''}`}
      onClick={goToMap}
    >
      <span className={`icon-cont ${isMobile ? 'mr--xs' : 'mr--s'}`}>
        <Icon name={PinMap} color="f0" size="ml" fill />
      </span>
      <span className={textCls}>Mappa</span>
    </button>
  );
};

GoToMapButton.propTypes = {
  goToMap: PropTypes.func,
  clsBtn: PropTypes.string,
};

GoToMapButton.defaultProps = {
  goToMap: () => {},
  clsBtn: '',
};

export default GoToMapButton;
