import React from 'react';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import { useCtxGlobal } from '@client/app/Container';

import SortSelect from '@components/common/SortSelect/index';

import BaloonMessageNoResults from './baloonMessageNoResults';

import './style.scss';

const NoResults = () => {
  const {
    model: {
      total,
      isAgencySrp,
      isPublisherPage,
      fewResults,
    },
    filtersProps: { filters },
  } = useCtxGlobal();

  if (total || isPublisherPage || filters.pId) {
    return null;
  }

  if (fewResults && fewResults.page > 1) {
    return (
      <Grid className="fill_no__sel nrHeader__mobhid mt--ms" justify="flex-end">
        <GridItem behavior="fixed">
          <SortSelect />
        </GridItem>
      </Grid>
    );
  }

  return (
    <BaloonMessageNoResults
      isAgencySrp={isAgencySrp}
      filters={filters}
    />
  );
};

export default NoResults;
